<template>
    <v-row>
        <v-col class="pa-5 my-7">
            <v-card v-if="customer != null" class="rounded-xl" style="padding-top: 75px">
                <v-card-text class="text-center py-0" style="position:absolute;top: -25px;z-index:2">
                    <v-hover v-slot="{ hover }">
                        <v-avatar @click="$refs.image.$refs.input.click()" size="100" color="accent" class="avatar white--text elevation-5" style="cursor:pointer">
                            <h1 v-if="!image.imgUrl">{{ initials }}</h1>
                            <v-img v-else :src="image.imgUrl"></v-img>
                            <div v-if="hover" class="d-flex" style="width:100%;height:100%;position:absolute;background-color:rgba(0,0,0,0.625)">
                                <v-icon color="white">mdi-pencil</v-icon>
                            </div>
                        </v-avatar>
                    </v-hover>
                </v-card-text>
                    <v-btn style="position:absolute;top: 12px;right:15px;z-index:3" to="/perfil" icon><v-icon>mdi-close</v-icon></v-btn>
                <v-card-text>
                    <v-form ref="form" v-model="formValid">
                        <v-card-title class="petu-green pt-0 pb-2 px-0">Editar perfil</v-card-title>
                        <v-card-subtitle class="px-0">
                            {{ tabs[tab].name }}
                        </v-card-subtitle>
                        <!-- general -->
                        <v-tabs v-model="tab" grow color="gray">
                            <v-tab  v-for="(tab, index) in tabs"  :key="'tab-'+index">
                                <v-icon>{{ tab.icon }}</v-icon>
                            </v-tab>
                        </v-tabs>
                        <div v-show="tab === 0">
                            <div>Detalles</div>
                            <v-file-input v-show="false" ref="image" @change="encodeImageFileAsURL" v-model="image"></v-file-input>
                            <v-container class="px-0">
                                <v-row>
                                    <v-col class="pb-0"><v-text-field v-model="customer.firstName" label="Nombre" :rules="firstNameRules" outlined dense></v-text-field></v-col>
                                    <v-col class="pb-0"><v-text-field v-model="customer.lastName" label="Apellido" :rules="lastNameRules" outlined dense></v-text-field></v-col>
                                </v-row>
                            </v-container>

                            <v-text-field v-model="customer.email" type="email" label="Email" :rules="emailRules" outlined dense disabled></v-text-field>
                            <v-text-field v-model="customer.phone" type="tel" label="Teléfono" v-mask="'(###) ###-####'" :rules="phoneRules" outlined dense></v-text-field>
                            <v-select v-model="customer.gender" :items="genders" label="Género" outlined dense></v-select>
                            <v-dialog v-model="show_password_dialog" width="500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn small v-bind="attrs" v-on="on" color="blue" class="no-uppercase rounded-xl" text>
                                        <v-icon small class="mr-2">mdi-form-textbox-password</v-icon>
                                        <span>Cambiar contraseña</span>
                                    </v-btn>
                                </template>

                                <v-card>
                                    <v-card-title class="text-h5 mb-7">Cambiar contraseña</v-card-title>
                                    <v-card-text>
                                        <v-text-field class="mb-7" v-model="current_password" type="password" label="Contraseña actual" :rules="current_password_rules" outlined dense></v-text-field>
                                        <v-text-field v-model="new_password" type="password" label="Contraseña nueva" :rules="[new_password_rules]" outlined dense></v-text-field>
                                        <v-text-field v-model="confirm_new_password" type="password" label="Confirmar contraseña nueva" :rules="[new_password_rules]" outlined dense></v-text-field>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                            <v-btn class="no-uppercase" text @click="show_password_dialog = false">Cancelar</v-btn>
                                            <v-btn class="no-uppercase" color="primary" text :disabled="!can_change_password" @click="change_password">Cambiar</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </div>

                        <!-- health -->
                        <div v-show="tab === 1">
                            <div>Altura</div>
                            <v-container class="px-0 pt-2 pb-0">
                                <v-row>
                                    <v-col class="pb-0"><v-text-field v-model="heightFeet" label="Pies" outlined :rules="heightFeetRules" suffix="'" dense></v-text-field></v-col>
                                    <v-col class="pb-0"><v-text-field v-model="heightInches" label="Pulgadas" outlined :rules="heightInchesRules" suffix="''" dense></v-text-field></v-col>
                                </v-row>
                            </v-container>

                            <div>Peso</div>
                            <v-container class="px-0 pt-2">
                                <v-row>
                                    <v-col class="pb-0"><v-text-field v-model="customer.weight" label="Peso" suffix="lbs" outlined :rules="weightRules" dense></v-text-field></v-col>
                                    <v-col class="pb-0"><v-text-field v-model="customer.weightGoal" label="Peso Meta" suffix="lbs" outlined :rules="weightRules" dense></v-text-field></v-col>
                                </v-row>
                            </v-container>
                        </div>
                        <!-- addresses -->
                        <div v-show="tab === 2">
                            <div class=" mb-3">Billing</div>
                            <address-component :value="billingAddress" @input="updateBAddress" editable dense ref="billingAddress"></address-component>
                            <div class=" mb-3">Shipping</div>
                            <address-component :value="shippingAddress" @input="updateSAddress" editable dense ref="shippingAddress"></address-component>
                        </div>
                    </v-form>
                </v-card-text>
                <v-card-actions class="px-5 pb-5">
                    <v-btn block @click="saveCustomer" color="primary" class="no-uppercase font-weight-bold">Guardar</v-btn>
                </v-card-actions>
            </v-card>
            <v-skeleton-loader v-else class="mx-auto" type="article, article, article, actions"></v-skeleton-loader>
            <v-overlay :value="saving">
                <v-card flat color="white" min-width="200">
                    <v-card-text>
                        <div class="subtitle-1 grey--text text--darken-2 text-center mb-2">Guardando...</div>
                        <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-overlay>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PetuCustomer } from '@/classes/customer';
import AddressComponent from '@/components/AddressComponent';
import { mask } from 'vue-the-mask';
import { _st } from '@/softech';
import { Auth } from 'aws-amplify';

export default {
    props: { id: { type: String, default: '' }},
    components: { AddressComponent },
    data:() => ({
        show_password_dialog    : false,
        current_password        : '',
        new_password            : '',
        confirm_new_password    : '',
        tab                     :        0,
        tabs                    : [{ icon: 'mdi-cog', name: 'General'}, { icon: 'mdi-human-male-height-variant', name: 'Salud'}, { icon: 'mdi-map-marker', name: 'Direcciones'},], 
        image                   : { imgUrl: '' },
        genders                 : [{ text: 'Masculino', value: 'm' }, { text: 'Femenino', value: 'f' }, { text: 'N/A', value: '' }],
        heightFeet              : '',
        heightInches            : '',
        saving                  : false,
        formValid               : true,
        encoded_image           : {},

        // rules
        firstNameRules      : [ v => !!v || 'First name is required' ],
        lastNameRules       : [ v => !!v || 'Last name is required' ],
        emailRules          : [
            v => !!v || 'E-mail is required',
            v => _st.isEmail(v) || 'E-mail must be valid',
        ],
        phoneRules          : [ 
            v => !!v || 'Phone is required',
            v => _st.isPhone(v) || 'Phone must be valid', 
        ],
        current_password_rules  : [ v => !!v || 'Campo es requerido' ],
        heightFeetRules     : [v => {
            if(!_st.isEmpty(v)) 
                return /^[2-8]{1}$/.test(v) || 'Feet must be valid';

            return true;
        }],
        heightInchesRules   : [v => {
            if(!_st.isEmpty(v))
                return (v >= 0 && v <= 11) || 'Pulgadas deben ser validas'

            return true;
        }],
        weightRules         : [v => {
            if(!_st.isEmpty(v))
                return /^[0-9]{2,3}$/.test(v) || 'Peso debe ser valido'

            return true;
        }],
    }),
    async mounted() {
        this.image          = { imgUrl: this.profilePic }
        this.heightFeet     = this.customer.heightFeet;
        this.heightInches   = this.customer.heightInches;
    },
    methods: {
        ...mapActions({ 
            updateCustomer: 'auth/updateCustomer' 
        }),
        getCustomerInitials() {
            return (new PetuCustomer(this.customer)).getInitials()
        },
        change_password() {
            if (!this.can_change_password) return
            Auth.currentAuthenticatedUser().then((user) => {
                return Auth.changePassword(user, this.current_password, this.new_password);
            }).then(() => {
                this.show_password_dialog = false
                this.$root.$emit('alert', { status: 'success', message: 'Su contraseña ha sido cambiada exitosamente' })
            }).catch(async (error) => {
                console.log(error)
                this.$root.$emit('alert', { status: 'error', message: 'Ha ocurrido un error' })
            })
        },
        async saveCustomer() {
            this.$refs.billingAddress.resetValidation();
            this.$refs.shippingAddress.resetValidation();
            this.$refs.form.validate();
            
            if( !this.formValid ) 
                return;

            let customer = new PetuCustomer( this.customer );

            
            customer.image = this.image; // this.image?.imgUrl;
            customer.data.heightFeet = this.heightFeet;
            customer.data.heightInches = this.heightInches;

            this.saving = true;

            await customer.save();
            await customer.load( this.customer.id );

            this.saving = false;
            this.updateCustomer({ customer });
        },
        encodeImageFileAsURL() {
            var reader = new FileReader();
            reader.onloadend = () => {
                this.image = {
                    name    : this.image.name,
                    size    : (this.image.size / 1024 / 1024) < 1 ? Math.round(this.image.size / 1024) + ' KB' : (this.image.size / 1024 / 1024).toFixed(2) + ' MB',
                    imgUrl  : reader.result,
                    data    : this.image,
                    primary : true,
                    order   : 0,
                    isNew   : true,
                }
            }
            reader.readAsDataURL( this.image );
        },
        new_password_rules(v) { 
            return (!!v && v === this.new_password) || 'Contraseñas no son iguales'
        },
        updateBAddress( a ) {
            this.customer.billingAddress1 = a.line1;
            this.customer.billingAddress2 = a.line2;
            this.customer.billingCity = a.city;
            this.customer.billingState = a.state;
            this.customer.billingZipcode = a.zipCode;
        },
        updateSAddress( a ) {
            this.customer.shippingAddress1 = a.line1;
            this.customer.shippingAddress2 = a.line2;
            this.customer.shippingCity = a.city;
            this.customer.shippingState = a.state;
            this.customer.shippingZipcode = a.zipCode;
        }
    },
    computed: {
        ...mapGetters({ 
            customer: 'auth/customer', profilePic: 'auth/profilePic' 
        }),
        initials() { 
            return (new PetuCustomer(this.customer)).getInitials() 
        },
        url() { 
            return this.image ? URL.createObjectURL(this.image) : null 
        },
        can_change_password() {
            return this.current_password.length > 0 && this.new_password.length > 0 && this.new_password === this.confirm_new_password
        },
        billingAddress() {
            return {
                name    : '',
                line1   : this.customer.billingAddress1,
                line2   : this.customer.billingAddress2,
                city    : this.customer.billingCity,
                state   : this.customer.billingState,
                zipCode : this.customer.billingZipcode
            };
        },
        shippingAddress() {
            return {
                name    : '',
                line1   : this.customer.shippingAddress1,
                line2   : this.customer.shippingAddress2,
                city    : this.customer.shippingCity,
                state   : this.customer.shippingState,
                zipCode : this.customer.shippingZipcode
            };
        },
    },
    directives: { mask }
}
</script>

<style scoped>
.txt-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.txt-middle {
    border-radius: 0;
}
.txt-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

</style>